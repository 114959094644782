import { push } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { EMAIL_VERIFICATION_ERROR } from '../types';

export default (action$) =>
    action$.pipe(
        ofType(EMAIL_VERIFICATION_ERROR),
        flatMap(({ requestMeta: { redirect } }) => of(push(redirect))),
    );
