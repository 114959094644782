import { alertsEpic } from '@clearscore/epic.alerts';

import verifyEpicSuccess from './epics/verify-success';
import verifyEpicError from './epics/verify-error';
import config from './alerts-config';

export default {
    alerts: alertsEpic(config),
    verifyEpicSuccess,
    verifyEpicError,
};
