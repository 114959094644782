/* eslint-disable import/prefer-default-export */
import createAction from '@clearscore/helpers.create-action';
import createRequestPayload from '@clearscore/helpers.create-request-payload';

import { EMAIL_VERIFICATION } from './types';

export const verifyEmail = ({ token, redirect, market }) =>
    createAction(
        EMAIL_VERIFICATION,
        createRequestPayload(
            'international',
            '/profile-service/v2/verify-email',
            'POST',
            { token },
            { market, token, redirect },
        ),
    );
