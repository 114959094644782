import type { FC } from 'react';
import React, { useEffect } from 'react';
import RainbowContainer from '@clearscore/rainbow.container';
import Loader from '@clearscore/rainbow.loader';
import withVertical from '@clearscore-group/ui.external-cs-auth.vertical';
import connect from '@clearscore/redux.connect';
import { useDispatch } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import { verifyEmail } from './libs/actions';
import epics from './libs/epics';

const getQueryStringRedirect = (locationSearch: string): string | null => {
    const urlParams = new URLSearchParams(locationSearch);
    return urlParams.get('redirect');
};

interface IMatchParams {
    token: string;
    market: string;
}

type TEmailVerificationProps = RouteComponentProps<IMatchParams>;

const EmailVerification: FC<TEmailVerificationProps> = ({ location: { search: querystring }, match: { params } }) => {
    const { token, market } = params;
    const redirect = getQueryStringRedirect(querystring) || '/my-account';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            verifyEmail({
                token,
                redirect,
                market,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RainbowContainer size={RainbowContainer.Size.SMALL} isFullHeight top={RainbowContainer.Spacing.BIG}>
            <Loader isFullPage theme={Loader.Theme.DARK} />
        </RainbowContainer>
    );
};

const ConnectedComponent = connect(null, null, { useRouter: true })(EmailVerification);

export default withVertical(ConnectedComponent, {
    name: 'verifyEmail',
    epics,
});
