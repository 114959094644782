import { push } from 'connected-react-router';
import { ofType } from 'redux-observable';
import { of, concat } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { actions as profileActions } from '@clearscore/redux.profile';

import { EMAIL_VERIFICATION_SUCCESS } from '../types';

export default (action$) =>
    action$.pipe(
        ofType(EMAIL_VERIFICATION_SUCCESS),
        flatMap(({ requestMeta: { redirect } }) => concat(of(profileActions.fetch()), of(push(redirect)))),
    );
