import { constants as alertConstants } from '@clearscore/redux.alerts';
import { EN_GB } from '@clearscore/config.i18n';
import i18next from 'i18next';

import { EMAIL_VERIFICATION_SUCCESS, EMAIL_VERIFICATION_ERROR, PAGE_NAMESPACE } from './types';

const t = i18next.getFixedT(EN_GB, PAGE_NAMESPACE);

const alertWithMessage = (message, alertType) => ({
    message,
    alertType,
    isDismissible: true,
    sticky: true,
    dismissTimeout: 5000,
});

export default {
    [EMAIL_VERIFICATION_SUCCESS]: () => alertWithMessage(t('alertSuccessVerify'), alertConstants.SUCCESS),
    [EMAIL_VERIFICATION_ERROR]: () => alertWithMessage(t('alertErrorVerify'), alertConstants.ERROR),
};
